import React from 'react';
import 'core-js/actual';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import GlobalSearchHeader from './GlobalSearchHeader';
import ProductBrowseResults from './ProductBrowseResults';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import algoliaInitEvent from 'search-insights';
import {ALGOLIA_APP_ID,ALGOLIA_API_KEY} from './service/ShophqService'
algoliaInitEvent('init', {
  appId: ALGOLIA_APP_ID,
  apiKey: ALGOLIA_API_KEY,
  useCookie: true,
});

// algoliaInitEvent('setUserToken', '');

ReactDOM.render(<GlobalSearchHeader />, document.getElementById('global-search-header-container'));

if (document.getElementById('global-search-header-mobile-container')) {
  ReactDOM.render(<GlobalSearchHeader />, document.getElementById('global-search-header-mobile-container'));
}

if (document.getElementById('product-browse-results-container')) {
  const history = createBrowserHistory();
  ReactDOM.render(<Router history={history} ><ProductBrowseResults /></Router>, document.getElementById('product-browse-results-container'));
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
