import React, { Component } from "react";
import ShopHqConnectedHits from "./ShopHqConnectedHits";
import ShopHqPaginationMobile from "../Mobile/ShopHqPaginationMobile";
// import CustomRefinementList from "../Filters/ShopHqCustomRefinementList ";
import ShopHqPaginationNavbar from "../PaginationSortBy/ShopHqPaginationNavbar";
import ShopHqDepartment from "../Filters/ShopHqDepartment";
import ShopHqCategory from "../Filters/ShopHqCategory";
import CustomCurrentRefinements from "../Filters/ShopHqCurrentRefinements";
import CustomBreadcrumb from "../Breadcrumb/ShopHqCustomBreadcrumb";
// import orderBy from 'lodash.orderby';
import ShopHqQueryWrapper from "./ShopHqQueryWrapper";
import { capitalizeFirstLetter } from "../../service/ShophqService";
import ShopHqDynamicFacets from "./ShopHqDynamicFacets";




// let attributes: any[] = [];
// const attributesGeneral = ["SkuPrice"];
// const attributesJewelry = ["GemstoneFeature", "Metal"];
// const attributesWatches = [
//   "BulovaCollection",
//   "CasioCollection",
//   "CitizenCollection",
//   "InvictaCollection",
//   "SeikoCollection",
//   "StuhrlingOriginalCol",
//   "WatchStyle",
//   "CaseSize",
//   "CaseShape",
//   "StrapBracelet",
//   "Movement",
//   "Complication",
// ];
// const attributesBeautyHealth = ["FragranceType", "SkinConcern"];
// const attributesElectronics = [
//   "Technology",
//   "TVSize",
//   "Condition",
//   "BraceletLength",
//   "BraceletStyle",
// ];

interface ShopHqQueryResultProps {
  searchClient: any;
  defaultBrand: any;
  filter: string;
  category: any;
}

export default class ShopHqQueryResult extends Component<ShopHqQueryResultProps> {
  render() {
    const { filter, category, defaultBrand} = this.props;   
    // switch (filter) {
    //   case "jewelry":
    //     attributes = Array.prototype.concat.apply(
    //       [],
    //       [attributesJewelry, attributesGeneral]
    //     );
    //     break;

    //   case "watches":
    //     attributes = Array.prototype.concat.apply(
    //       [],
    //       [attributesWatches, attributesGeneral]
    //     );
    //     break;

    //   case "beauty":
    //     attributes = Array.prototype.concat.apply(
    //       [],
    //       [attributesBeautyHealth, attributesGeneral]
    //     );
    //     break;

    //   case "electronics":
    //     attributes = Array.prototype.concat.apply(
    //       [],
    //       [attributesElectronics, attributesGeneral]
    //     );
    //     break;

    //   default:
    //     attributes = Array.prototype.concat.apply([], [attributesGeneral]);
    // }

    return (
      <ShopHqQueryWrapper>
        {/* Displaying  Breadcumb items  based on current category selection*/}
        <CustomBreadcrumb
          attributes={["Categories.lvl0", "Categories.lvl1", "Categories.lvl2"]}
          currentCategory={category}
        />
        <ShopHqPaginationMobile />
        <div className="row">
          <div className="container__search-selected-refinement">
            <div id="search-selected-refinement">
          {/* Displaying  Selected Facet values and Clear all button to reset Facet selections*/}
              <CustomCurrentRefinements />
              {/* <ShopHqCategory attribute="Department">
                <ShopHqDepartment
                  attributes={[
                    "Categories.lvl0",
                    "Categories.lvl1",
                    "Categories.lvl2"
                  ]}
                  limit={40}
                  defaultRefinement={capitalizeFirstLetter(category)}
                  showParentLevel={true}
                />
              </ShopHqCategory> */}
              {/* <hr className="line"></hr> */}
              {/* <CustomRefinementList
                  attribute={"Brand"}
                  limit={10}
                  showMoreLimit={100}
                  defaultRefinement={defaultBrand ? [defaultBrand] :[]}
                  showMore={true}
                  transformItems={(items) =>
                    orderBy(items, ['label', 'count'], ['asc', 'desc'])
                  }
                /> */}
              {/* {attributes.filter(a => a!=="SkuPrice").map((attribute, index) => (
                <CustomRefinementList
                  key={`crl${index}`}
                  attribute={attribute}
                  limit={10}
                  showMoreLimit={100}
                  showMore={true}
                  transformItems={(items) =>
                    orderBy(items, ['label', 'count'], ['asc', 'desc'])
                  }
                />
              ))} */}
              {/* Component to display facets */}
              <ShopHqDynamicFacets  defaultBrand={defaultBrand} defaultRefinement={capitalizeFirstLetter(category)}/>
            </div>
          </div>
          <div id="product-browse-results-refinements" className="container__product-browse-results-refinements">
            <div id="product-search-items">
              <ShopHqConnectedHits />
              <hr className="line filter-line" />
              <div className="row search-page">
                <ShopHqPaginationNavbar location="bottom" />
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </ShopHqQueryWrapper>
    );
  }
}

