
import {  
  connectStateResults,
  connectDynamicWidgets,
} from 'react-instantsearch-dom';
import { capitalizeFirstLetter } from '../../service/ShophqService';
import ShopHqCategory from '../Filters/ShopHqCategory';

import ShopHqCustomRangeSlider from '../Filters/ShopHqCustomPriceSlider';
import ShopHqCustomRefinementList from '../Filters/ShopHqCustomRefinementList ';
import ShophqDepartment from '../Filters/ShopHqDepartment';

const DynamicWidgetsForFacets = {
  RefinementList:ShopHqCustomRefinementList,
  ShopHqCustomRangeSlider,
  HierarchicalMenu:ShophqDepartment,
};

const ShopHqDynamicFacets = (props: any) => {
  // Destructing SearchResults and DynamicFacetDisplay from props
const {searchResults,props:dynamicFacetDisplay,defaultBrand,defaultRefinement}=props
  // Destructing attributesToRender from DynamicFacetDisplay. attributesToRender is an array, collection of facet(string).
const {attributesToRender}=dynamicFacetDisplay;
//Assigning userData to items . userData contains all the custom data returned from Rule.In our case it's contain dynamic facet data.
const items=searchResults?.userData
  if(items){
//Filtering userData using keyword  "DynamicFacets" which contains the DynamicFacet data list.
    let fa: any[] = [];
    items
      .filter(({ type }:any) => type === "DynamicFacets")
      .map((m:any) => m?.DF
      .map((f:any) => fa.push(f)));
// Assign the required UI component for facets
    const dynamicFiltersList = fa.map(({ FN, TYPE, LABEL,IsSearchable,AttributesList }) => ({
      attribute: FN,
      widgetName: LABEL ?LABEL :FN,
      isSearchable:IsSearchable?true:false,
      type:TYPE,
      AttributesList:AttributesList,
      Widget: DynamicWidgetsForFacets[TYPE as keyof typeof DynamicWidgetsForFacets] || ShopHqCustomRefinementList,
    }));
    if (dynamicFiltersList?.length > 0) {        
        return (
          <>
          {/* Removing duplication and structuring facet display order */}
          {
           Array.from(new Map(dynamicFiltersList.map(item =>[item['attribute' as keyof object], item])).values())
          .sort((a,b) => {   
            const index1 = attributesToRender.indexOf(a?.attribute);      
            const index2 = attributesToRender.indexOf(b?.attribute);      
            return ( (index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity) );     
          })
          .map(({ attribute, Widget,widgetName,isSearchable,type,AttributesList }:any,f) => type=== "HierarchicalMenu"? 
   
   <><ShopHqCategory attribute={widgetName}>
    <ShophqDepartment
          attributes={AttributesList}
          limit={40}
          defaultRefinement={defaultRefinement}
          showParentLevel={true}
        /> 
        </ShopHqCategory>
        <hr className="line"></hr>
        </>:<Widget attribute={attribute} 
              label={widgetName}
              key ={f}
              defaultBrand={defaultBrand}
              isSearchable={isSearchable}
              /> )}
          </>
        );
      }
  }

return null
}

{/* Connecting Algolia's DynamicWidgets and StateResults connector with DynamicFacets component */}
export default connectDynamicWidgets(connectStateResults(ShopHqDynamicFacets));

