import React, { Component } from "react";
import { getThumbnailNumber } from "../../service/ShophqService";
import algoliaEventAction from "search-insights";

interface ShopHqHitProps {
  hit: any;
  index: any;
}
interface ShopHqHitState {
  translate: any;
  transition: any;
  offerCode: any;
  dimensions: any;
}

let algoliaData = {};
let browserWindow = window as any;

export default class ShopHqHit extends Component<
  ShopHqHitProps,
  ShopHqHitState
> {
  container: any;
  constructor(props: ShopHqHitProps) {
    super(props);
    this.state = {
      translate: 0,
      transition: 0,
      offerCode: "",
      dimensions: null,
    };
  }

  componentDidMount() {
    this.setState({
      dimensions: {
        width: this.container.offsetWidth - 1,
        height: this.container.offsetHeight,
      },
    });
  }

  renderContent() {
    const { hit, index: hitPosition } = this.props;
    const imageUrl = browserWindow.config.imageUrl;
    const maxThumbs = browserWindow.screen.width > 768 ? 5 : 3;
    const thumbMarginRight = browserWindow.screen.width > 768 ? 10 : 5;

    let valuePay = hit.OfferPriceLow
      ? Math.round((hit.OfferPriceLow * 100) / 6) / 100
      : 0;
    let originalPrice = hit.ListPriceLow;
    let arrayThumbnails = hit.Thumbnails?.filter((img: any) => {
      return img !== " ";
    });

    algoliaEventAction("getUserToken", {}, (err, userToken) => {
      if (err) {
        console.error(err);
        return;
      }
    });
    browserWindow.algoliaEventAction = algoliaEventAction;

    return (
      <>
        <div className="quickview-hovereffect">
          <a
            id={`${hit.OfferCode}-pdp-link1`}
            href={hit.ProductUrl ? hit.ProductUrl : 0}
            onClick={() => {
              algoliaEventAction("clickedObjectIDsAfterSearch", {
                eventName: "PLP: Quickview Hover",
                index: browserWindow.config.allIndex,
                queryID: hit.__queryID,
                objectIDs: [hit.objectID],
                positions: [hitPosition + 1],
              });
              algoliaData = {
                data: {
                  index: browserWindow.config.allIndex,
                  queryID: hit.__queryID,
                  objectIDs: [hit.objectID],
                  positions: [hitPosition + 1],
                },
              };
              browserWindow.localStorage.setItem(
                "algoliaData",
                JSON.stringify(algoliaData)
              );
            }}
            target="_self"
          >
            <div
              className={`line-class-overlay ${hit.PriceHandle?.replace(
                /\s+/g,
                "-"
              )
                .replace(/'/g, "")
                .toLowerCase()}`}
            ></div>

            <img
              id={`${hit.OfferCode}-prod-img`}
              alt={`Image of product ${hit.OfferCode}`}
              className="lazy img-responsive"
              data-original={`${imageUrl}${
                this.state.offerCode === ""
                  ? hit.OfferCode
                  : hit.OfferCode + "_" + this.state.offerCode
              }?DefaultImage=1&$472x472_jpg$`}
              src={`${imageUrl}${
                this.state.offerCode === ""
                  ? hit.OfferCode
                  : hit.OfferCode + "_" + this.state.offerCode
              }?DefaultImage=1&$472x472_jpg$`}
              role="image"
            />
          </a>
          <div className="quickview-overlay" id={`btnQV-${hit.OfferCode}`}>
            <a
              id={`${hit.OfferCode}-qv-link`}
              className="addQuickViewLink"
              data-toggle="modal"
              data-offercode={hit.OfferCode}
              data-colorcode="Default"
              href="#shophq"
            >
              QUICKVIEW
            </a>
          </div>
        </div>
        {/* Inline Rating (leaving here in case the business wants it back in use) */}
        {/*
        <div 
          data-bv-show="inline_rating"
          data-bv-product-id={hit.OfferCode}
          data-bv-redirect-url={hit.ProductUrl}>
        </div>
        */}
        <div
          className={`sku-row${
            arrayThumbnails?.length > maxThumbs ? " is-carousel" : ""
          }`}
          data-width={this.state.dimensions.width}
        >
          {arrayThumbnails?.length > maxThumbs ? (
            <>
              {/* Make a fake Owl Carousel */}

              {/* Previous Button */}
              {/* The translate increment below should be: (overall_width / max_num_thumbs) */}
              <div
                className="image-carousel-controls prev"
                onClick={() => {
                  this.state.translate < 0 &&
                    this.setState({
                      translate:
                        this.state.translate +
                        (this.state.dimensions.width / maxThumbs +
                          thumbMarginRight),
                    });
                }}
              >
                <i className="fa fa-angle-left fa-stack-2x"></i>
              </div>

              {/* The images for the carousel */}
              <div className="image-carousel">
                <div
                  id={`${hit.OfferCode}-color-carousel`}
                  className="owl-carousel owl-loaded"
                >
                  <div className="owl-stage-outer">
                    <div
                      className="owl-stage"
                      style={{
                        transform:
                          "translate3d(" +
                          this.state.translate +
                          "px, 0px, 0px)",
                        transition:
                          "all " + this.state.transition + "s ease 0s",
                        width: `${
                          arrayThumbnails?.length *
                          (this.state.dimensions.width / maxThumbs +
                            (arrayThumbnails?.length - 1) * thumbMarginRight)
                        }px`,
                      }}
                    >
                      {/*
                      The width of DIV.owl-item should be:
                      (((100% / max-thumbs) - (margin-right * (max-thumbs -1))) - 28px)

                      note: 28 pixels resides within main.css on TFS and gets bundled into cb-common.css
                  */}
                      {arrayThumbnails?.map((thumbnail: any, index: any) => (
                        <div
                          key={`sdja${index}`}
                          className={`owl-item ${index === 0 ? "active" : ""}`}
                          style={{
                            width: `${
                              (this.state.dimensions.width -
                                (thumbMarginRight * (maxThumbs - 1) + 28)) /
                              maxThumbs
                            }px`,
                            marginRight:
                              index !== arrayThumbnails?.length - 1
                                ? `${thumbMarginRight}px`
                                : 0,
                          }}
                          onClick={(event) =>
                            this.changeImage(
                              thumbnail,
                              event,
                              arrayThumbnails?.length
                            )
                          }
                        >
                          <img
                            data-original={`//${thumbnail.replace(
                              /\&\$40x40_jpg\$/,
                              "&$240x240_jpg$"
                            )}`}
                            src={`//${thumbnail.replace(
                              /\&\$40x40_jpg\$/,
                              "&$240x240_jpg$"
                            )}`}
                            title={hit.Title}
                            alt={hit.Title}
                            className={`lazy img-responsive sku-img ${
                              index === 0 ? "color-selected" : "color"
                            }`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Next Button */}
              <div
                className="image-carousel-controls next"
                onClick={() => {
                  let movement =
                    (arrayThumbnails?.length - maxThumbs) *
                    (-1 *
                      (this.state.dimensions.width / maxThumbs +
                        thumbMarginRight));

                  movement !== this.state.translate &&
                    this.setState({
                      translate:
                        this.state.translate -
                        (this.state.dimensions.width / maxThumbs +
                          thumbMarginRight),
                    });
                  this.state.transition === 0 &&
                    this.setState({ transition: 0.2 });
                }}
              >
                <i className="fa fa-angle-right fa-stack-2x"></i>
              </div>
            </>
          ) : (
            <>
              {/*
                No carousel. Just a set of 5 or less imags spread across 100% width evenly with 10px gap in between 
                TODO: Look into changing the classname since it's not a carousel => e.g. `image-container`
            */}
              <div className="image-container">
                <div className={`image-container__${hit.OfferCode}-color`}>
                  <div className="image-container__container">
                    {arrayThumbnails?.length > 0 ? (
                      arrayThumbnails?.map((thumbnail: any, index: any) => (
                        <div
                          className="image-container__image"
                          onClick={(event) =>
                            this.changeImage(
                              thumbnail,
                              event,
                              arrayThumbnails?.length
                            )
                          }
                        >
                          <img
                            key={`sdja${index}`}
                            data-original={`//${thumbnail.replace(
                              /\&\$40x40_jpg\$/,
                              "&$240x240_jpg$"
                            )}`}
                            src={`//${thumbnail.replace(
                              /\&\$40x40_jpg\$/,
                              "&$240x240_jpg$"
                            )}`}
                            title={hit.Title}
                            alt={hit.Title}
                            className={`lazy img-responsive sku-img ${
                              index === 0 ? "color-selected" : "color"
                            }`}
                            style={{
                              width: `${
                                (this.state.dimensions.width -
                                  thumbMarginRight * (maxThumbs - 1)) /
                                maxThumbs
                              }px`,
                              marginRight:
                                index !== arrayThumbnails?.length - 1
                                  ? `${thumbMarginRight}px`
                                  : 0,
                            }}
                          />
                        </div>
                      ))
                    ) : (
                      <img
                        data-original={`//images.shophq.com/is/image/ShopHQ/${hit.OfferCode}?DefaultImage=1&$240x240_jpg$`}
                        src={`//images.shophq.com/is/image/ShopHQ/${hit.OfferCode}?DefaultImage=1&$240x240_jpg$`}
                        title={hit.Title}
                        alt={hit.Title}
                        className="lazy img-responsive sku-img color-selected"
                        style={{
                          width: `${
                            (this.state.dimensions.width -
                              thumbMarginRight * (maxThumbs - 1)) /
                            maxThumbs
                          }px`,
                          marginRight: 0,
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>{" "}
        <div className="search-product-detail">
          <div className="BrowseProducts-caption">
            <a
              id={`${hit.OfferCode}-pdp-link2`}
              className="blue-hover ItemDescription-3-Line"
              href={hit.ProductUrl ? hit.ProductUrl : 0}
              onClick={() => {
                algoliaEventAction("clickedObjectIDsAfterSearch", {
                  eventName: "PLP: Product Clicked",
                  index: browserWindow.config.allIndex,
                  queryID: hit.__queryID,
                  objectIDs: [hit.objectID],
                  positions: [hitPosition + 1],
                });
                algoliaData = {
                  queryID: hit.__queryID,
                  objectIDs: [hit.objectID],
                  positions: [hitPosition + 1],
                };
                browserWindow.localStorage.setItem(
                  "algoliaData",
                  JSON.stringify(algoliaData)
                );
              }}
              target="_self"
              dangerouslySetInnerHTML={{ __html: hit.Title }}
            ></a>
          </div>

          {/* Sale Price */}
          <div className="sale-price-section">
            <span className="sale-price">
              {hit.OfferPriceLow?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}{" "}
              {hit.OfferPriceLow === hit.OfferPriceHigh
                ? ""
                : ` - ${hit.OfferPriceHigh.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`}
            </span>
          </div>

          {/* List Price */}
          {hit.OfferPriceLow !== originalPrice &&
            originalPrice > 0 &&
            hit.PercentOff > 0 && (
              <>
                <div className="list-price-section">
                  <span className="list-price">
                    (
                    {originalPrice.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}{" "}
                    {originalPrice === hit.ListPriceHigh
                      ? ""
                      : ` - ${hit.ListPriceHigh.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}`}
                    )
                  </span>
                </div>
              </>
            )}

          {/* Price Savings Percentage */}
          {hit.PercentOff > 0 && (
            <div className="price-saved-section">
              <span className="percent-saved">
                {Math.round(hit.PercentOff)}% Savings
              </span>
            </div>
          )}
          {(hit.Refinements as string[]).find((val: string) => {
            return val === "6 ValuePay";
          }) && (
            <div className="shophq-green">
              <span>
                or 6 ValuePay :{" "}
                {valuePay.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </span>
            </div>
          )}
          <div id={`BVRRInlineRating-${hit.OfferCode}`}></div>
        </div>
      </>
    );
  }

  changeImage(thumbnail: any, event: any, totalNumber: any) {
    let parentNode = event.target.parentNode.parentNode;
    let children = parentNode.children;
    let number = getThumbnailNumber(thumbnail);

    for (var i = 0; i < totalNumber; i++) {
      if (children[i].firstChild.classList.contains("color-selected")) {
        children[i].firstChild.classList.remove("color-selected");
        children[i].firstChild.classList.add("color");
      }
    }
    event.target.classList.remove("color");
    event.target.classList.add("color-selected");
    this.setState({ offerCode: number });
  }

  render() {
    const { hit, index: hitPosition } = this.props;

    return (
      <div
        key={`hit${hit.OfferCode}`}
        id={hit.OfferCode}
        className="search-product-detail-container"
        ref={(el) => (this.container = el)}
      >
        {this.state.dimensions && this.renderContent()}
      </div>
    );
  }
}
