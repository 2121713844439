import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from "react";

interface Props extends PropsWithChildren<any> {
  attribute: string;
}

export default function ShopHqCategoryTab({ attribute, children }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const attributeName = attribute.replace(" ", "");

  const handleClickOutside = useCallback(
    (event: any) => {
      if (ref.current && !ref.current?.contains(event.target as Node)) {
        const element = document.getElementById(`ctl-${attributeName}`);
        element?.classList.remove("in");
      }
    },
    [attributeName]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="panel-default search-refinement-group" ref={ref}>
      <div
        id={attributeName}
        className="panel-heading header"
        data-toggle="collapse"
        data-target={".cl-" + attributeName}
        role="button"
      >
        <span className={"floatLeft"} data-grouporder={attributeName} />
        <span className="header-title">{attribute}</span>
      </div>
      <div
        id={"ctl-" + attributeName}
        className={"panel-collapse collapse cl-" + attributeName}
        aria-labelledby={attributeName}
      >
        <div className="panel-body">{children}</div>
      </div>
    </div>
  );
}
