import React, { Component } from "react";
import QueryString from "qs";
import { Helmet } from "react-helmet";

export interface ShopHqNoResultsFoundProps {}

const oopsHtml = `<div id="banner-J6E4Bxkm45ALStbguazx" class="banner-wrapper banner__oops-banner">
                    <a href="https://www.christopherandbanks.com" title="Search Results not found">
                      <span>
                        <picture>
                          <source
                            srcset="https://www.christopherandbanks.com/images/global/091523_Oops%20Asset_1170x604.png"
                            media="(min-width: 992px)">
                          <source
                            srcset="https://www.christopherandbanks.com/images/global/091523_Oops%20Asset_1170x604.png">
                          <img src="https://www.christopherandbanks.com/images/global/091523_Oops%20Asset_1170x604.png"
                            alt="Search Results not found" title="Search Results not found" loading="lazy" class="img-responsive">
                        </picture>
                      </span>
                    </a>
                  </div>`;
export default class ShopHqNoResultsFound extends Component<ShopHqNoResultsFoundProps> {
  render() {
    let search = QueryString.parse(window.location.search, { ignoreQueryPrefix: true });
    return (
      <div className="row">
        <div>
          <div id="search-noresults-suggestions">
            {search.q ? (
              <>
                <div dangerouslySetInnerHTML={{ __html: oopsHtml }}></div>
                <div id="banner-19vn3eBccHvN5GoYzvhC" style={{ marginBottom: "30px", marginTop: "30px" }}>
                  <Helmet>
                    <script type="module">
                      {`
                      import algoliasearch from 'https://cdn.jsdelivr.net/npm/algoliasearch@4/dist/algoliasearch-lite.esm.browser.js';
                      const client = algoliasearch('TUTM6TNAMV', 'e5322890bc7a0ea4264974fa5016e4cc');
                      const index = client.initIndex('shq_skus_prod_en');
                      $(function () {
                        var banner = document.querySelector("#banner-19vn3eBccHvN5GoYzvhC");
                            var id = banner.id;
                            var boutique = banner.querySelector('[data-module="boutique"]');
                            var recommendations = banner.querySelector('[data-module="recommendations"]');
                            var templateBoutique = '<div className="boutique-carousel__item prodTrack shq-boutiqueCarousel" data-productlist="{{ProductList}}" data-productposition="{{ProductPosition}}" data-offercode="{{OfferCode}}" data-topline="{{ToplineAttr}}"> <div className="boutique-carousel__item-image"> <div className="quickview-hovereffect"> <a href="/Product/{{OfferCode}}"><img className="img-responsive" width="480" height="480" src="https://images.shophq.com/is/image/ShopHQ/{{OfferCode}}?DefaultImage=1\&$480x480_jpg$&op_sharpen=0" alt="{{Title}} - {{OfferCode}}" loading="lazy" role="image"> <p className="text-center"><span style="color: #ce0037; font-size: 16px;">{{PriceHandle}}</span></p><p className="text-center"><span style="color: #ce0037; font-size: 16px;">{{OfferPrice}}</span> - <span className="list-price">{{ListPrice}}<span></p><p className="text-center">{{Title}}</p></a> <div className="quickview-overlay" id="quickview__{{OfferCode}}"> <a id="QuickView_{{OfferCode}}" className="addQuickViewLink" data-toggle="modal" data-offercode="{{OfferCode}}" href="#shophq" aria-label="Offer {{OfferCode}}">QUICKVIEW</a> </div> </div> </div> </div>';
                            var templateRecommendations = '<div className="boutique-carousel__item prodTrack shq-recommendationsCarousel" data-productlist="{{ProductList}}" data-productposition="{{ProductPosition}}" data-offercode="{{OfferCode}}" data-topline="{{ToplineAttr}}"> <div className="boutique-carousel__item-image"> <div className="quickview-hovereffect"> <a href="/Product/{{OfferCode}}"> <img className="img-responsive" width="140" height="140" src="https://images.shophq.com/is/image/ShopHQ/{{OfferCode}}?DefaultImage=1&$150x150_jpg$" alt="{{Topline}} - {{OfferCode}}" loading="lazy" role="image"> </a> <div className="quickview-overlay" id="quickview"> <a id="QuickView_{{OfferCode}}" className="addQuickViewLink" data-toggle="modal" data-offercode="{{OfferCode}}" href="#" aria-label="Offer {{OfferCode}}">QUICKVIEW</a> </div> </div> </div></div>';
                            if (recommendations) {
                                var cookie = typeof $.cookie !== "undefined" ? $.cookie("RViewSHOP1") : false;
                                if (typeof cookie !== "undefined" && cookie.length > 0) {
                                    var offerCode = decodeURIComponent(cookie).split("|").shift().replace("#", "");
                                    console.log("Checking recommendations for Offer Code: " + offerCode);
                                    getRecommendations(offerCode).then(function (data) {
                                        var items = pickRecommendationSet(data, 4);
                                        if (items) {
                                            var preparedItems = prepareRecommendationItems(items);
                                            initBoutiqueCarousel(recommendations, templateRecommendations, preparedItems);
                                        } else {
                                            console.log("No recommendations found");
                                            initBoutiqueCarousel(boutique, templateBoutique);
                                        }
                                    });
                                } else {
                                    console.log("No cookie found");
                                    initBoutiqueCarousel(boutique, templateBoutique);
                                }
                            } else {
                                initBoutiqueCarousel(boutique, templateBoutique);
                            }
                            function initBoutiqueCarousel(selector, template, items) {
                                var el;
                                if (typeof selector === "string") {
                                    el = document.querySelector(selector);
                                    if (!el) return;
                                } else {
                                    el = selector;
                                }
                                var boutique = el.querySelector(".boutique-carousel__wrapper");
                                if (!boutique) return;
                                el.style.display = "block";
                                var title = boutique.dataset.title.replace(/ /g, "");
                                if (typeof items === "undefined") {
                                    var url = boutique.dataset.boutiqueurl ? boutique.dataset.boutiqueurl : null;
                                    if (!url) return;
                                    if ("IntersectionObserver" in window) {
                                        var observer = new IntersectionObserver(function (entries) {
                                            entries.forEach(function (entry) {
                                                if (entry.intersectionRatio > 0) {
                                                    getItems();
                                                    observer.unobserve(entry.target);
                                                }
                                            });
                                        }, {
                                            rootMargin: "500px"
                                        });
                                        observer.observe(boutique);
                                    } else {
                                        getItems();
                                    }
                                } else {
                                    $(boutique).html(renderList(template, items));
                                    initCarousel(boutique);
                                }

                                function getItems() {
                                    var items = load(id) || null;
                                    if (items) {
                                        var preparedItems = prepareBoutiqueItems(items);
                                        $(boutique).html(renderList(template, preparedItems));
                                        console.log('storage items: ', items)
                                        initCarousel(boutique, items);
                                    } else {
                                        let results = index.search("", {
                                            "getRankingInfo": true,
                                            "analytics": false,
                                            "enableABTest": false,
                                            "hitsPerPage": 30,
                                            "attributesToRetrieve": "*",
                                            "attributesToSnippet": "*:20",
                                            "snippetEllipsisText": "…",
                                            "responseFields": "*",
                                            "explain": "*",
                                            "ruleContexts": ["todays-special-deals"],
                                            "maxValuesPerFacet": 24,
                                            "page": 0,
                                            "facets": ["*"],
                                            "facetFilters": [
                                                ["PriceHandle:This Day Only", "PriceHandle:Must Have Buy", "PriceHandle:Daily Digital Deals"]
                                            ]
                                        }).then(({
                                            hits
                                        }) => {
                                            return hits;
                                        });
                                        let resultData = async () => {
                                            const items = await results;
                                            console.log('items: ', items)
                                            if (items.length > 0) {
                                                save(id, items, 15);
                                                var preparedItems = prepareBoutiqueItems(items);
                                                $(boutique).html(renderList(template, preparedItems));
                                                initCarousel(boutique, items);
                                            } else {
                                                $(boutique).html("<p>Check back soon!</p>");
                                            }
                                        }
                                        resultData();
                                    }
                                }

                                function initCarousel(el, value) {
                                    // DEBUG UI OFFERCODE
                                    value.forEach(function (r, index) {
                                        $('#QuickView_'+r.OfferCode).click(function () {
                                            console.log('Debug offercode: ', r.OfferCode)
                                        })
                                    })
                                    $(el).addClass("owl-carousel").owlCarousel({
                                        dots: false,
                                        nav: true,
                                        navText: ["<span style='padding-right: 10px;'><i class='fa fa-3x fa-angle-left'></i></span>", "<span style='padding-left: 10px;'><i class='fa fa-3x fa-angle-right'></i></span>"],
                                        slideBy: 'page',
                                        responsive: {
                                            0: {
                                                items: 1,
                                                loop: true
                                            },
                                            481: {
                                                items: 2,
                                                loop: true
                                            },
                                            768: {
                                                items: 3,
                                                loop: true
                                            },
                                            992: {
                                                items: 3,
                                                loop: true
                                            },
                                            1200: {
                                                items: 3,
                                                loop: true
                                            }
                                        }
                                    });
                                }

                                function renderList(template, items, it) {
                                    var productList = window.location.pathname + "__" + title;
                                    var insertHtml = "";
                                    items.forEach(function (item, index) {
                                        item.ProductList = productList;
                                        item.ProductPosition = index + 1;
                                        insertHtml += render(template, item, it);
                                    });
                                    return insertHtml;
                                }
                            }

                            function getRecommendations(offerCode) {
                                var url = "/Product/GetRecommendationProducts";
                                var recommendationTypes = ["PeopleWhoViewedAlsoViewed", "FrequentlyPurchasedTogether", "PeopleWhoBoughtAlsoBought"];
                                var defaultParams = {
                                    offerCode: "",
                                    maxItems: 10,
                                    recommendationType: 0,
                                    category: "",
                                    brand: ""
                                };
                                var ajaxPromises = [];
                                var ajaxResults = {};
                                var deferred = $.Deferred();
                                $.each(recommendationTypes, function (index, type) {
                                    var params = JSON.parse(JSON.stringify(defaultParams));
                                    params.offerCode = offerCode;
                                    params.recommendationType = index;
                                    var queryString = $.param(params);
                                    var query = $.post(url + "?" + queryString).done(function (response) {
                                        ajaxResults[type] = response[type];
                                    }).fail(function (error) {
                                        console.log("Error getting recommendations for " + type, error);
                                        $("#variant-c-recommendations").hide();
                                    });
                                    ajaxPromises.push(query);
                                });
                                $.when.apply($, ajaxPromises).done(function () {
                                    deferred.resolve(ajaxResults);
                                }).fail(function () {
                                    console.log("Error getting all recommendations", error);
                                    $("#variant-c-recommendations").hide();
                                    deferred.reject();
                                });
                                return deferred.promise();
                            }
                            function pickRecommendationSet(data, minimum) {
                                if (data["FrequentlyPurchasedTogether"] && data["FrequentlyPurchasedTogether"].length >= minimum) {
                                    console.log("Showing FrequentlyBought");
                                    return data["FrequentlyPurchasedTogether"];
                                }
                                if (data["PeopleWhoBoughtAlsoBought"] && data["PeopleWhoBoughtAlsoBought"].length >= minimum) {
                                    console.log("No FrequentlyBought, showing AlsoBought");
                                    return data["PeopleWhoBoughtAlsoBought"];
                                }
                                if (data["PeopleWhoViewedAlsoViewed"] && data["PeopleWhoViewedAlsoViewed"].length >= minimum) {
                                    console.log("No AlsoBought, showing AlsoViewed");
                                    return data["PeopleWhoViewedAlsoViewed"];
                                }
                                return null;
                            }

                            function prepareRecommendationItems(items) {
                                items.forEach(function (item, index) {
                                    var percentOffText = item.PercentOff.DisplayPercentage.replace("(", "").replace(")", "");
                                    if (parseInt(percentOffText) >= 15) {
                                        item.BadgeText = percentOffText;
                                    } else {
                                        item.BadgeText = "";
                                    }
                                    item.ToplineAttr = item.Topline.replace(/"/g, "");
                                });
                                return items;
                            }

                            function prepareBoutiqueItems(items) {
                                items.forEach(function (item, index) {
                                    var priceBadge = "hello";
                                    if (item.OfferId) {
                                        var percentOffPriceLow = Math.round(((item.ListPriceLow - item.OfferPriceLow) / item.ListPriceLow) * 100);
                                        var percentOffPriceHigh = Math.round(((item.ListPriceHigh - item.OfferPriceHigh) / item.ListPriceHigh) * 100);
                                        if (item.OfferPriceLow === item.OfferPriceHigh) {
                                            item.OfferPrice = "$" + item.OfferPriceHigh;
                                        } else {
                                            item.OfferPrice = "$" + item.OfferPriceLow + " – " + "$" + item.OfferPriceHigh;
                                        }
                                        if (item.ListPriceLow === item.ListPriceHigh) {
                                            item.ListPrice = "$" + formatMoney(item.ListPriceHigh);
                                        } else {
                                            item.ListPrice = "$" + item.ListPriceLow + " – " + "$" + formatMoney(item.ListPriceHigh);
                                        }
                                        if (item.Title.toLowerCase().indexOf("waterford") >= 0 || percentOffPriceLow > 99 || percentOffPriceLow < 15 || percentOffPriceHigh > 99 || percentOffPriceHigh < 15) {
                                            item.PercentOffPrice = "";
                                        } else if (item.ListPriceLow !== item.ListPriceHigh) {
                                            item.PercentOffPrice = percentOffPriceLow + "% – " + percentOffPriceHigh + "% off";
                                        } else {
                                            item.PercentOffPrice = percentOffPriceHigh + "% off";
                                        }
                                        console.log(item.BadgeText, '?')
                                        if (!item.BadgeText) item.BadgeText = item.PercentOffPrice;
                                        item.ToplineAttr = item.Title.replace(/"/g, "");
                                    }
                                });
                                return items;
                            }

                            function render(template, options) {
                                return template.replace(/\{\{\s?([\w.]+)\s?\}\}/g, function (match, variable) {
                                    return (variable.split(".").reduce(function (previous, current) {
                                        return previous[current];
                                    }, options) || "");
                                });
                            }

                            function formatMoney(n, c, d, t) {
                                var c = isNaN((c = Math.abs(c))) ? 2 : c,
                                    d = d == undefined ? "." : d,
                                    t = t == undefined ? "," : t,
                                    s = n < 0 ? "-" : "",
                                    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
                                    j = (j = i.length) > 3 ? j % 3 : 0;
                                return (s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(d{3})(?=d)/g, "$1" + t) + (c && Math.abs(n - i).toFixed() > 0 ? d + Math.abs(n - i).toFixed(c).slice(2) : ""));
                            }

                            function save(key, jsonData, expirationMin) {
                                var expirationMS = expirationMin * 60 * 1000;
                                var record = {
                                    value: JSON.stringify(jsonData),
                                    timestamp: new Date().getTime() + expirationMS
                                };
                                localStorage.setItem(key, JSON.stringify(record));
                                return jsonData;
                            }

                            function load(key) {
                                var record = JSON.parse(localStorage.getItem(key));
                                if (!record) {
                                    return false;
                                }
                                return new Date().getTime() < record.timestamp && JSON.parse(record.value);
                            }
                        }
                      `}
                    </script>
                  </Helmet>
                  <div className="boutique-carousel__container" data-module="boutique">
                    <div className="row">
                      <div className="col-xs-12">
                        <h3 className="dynamic__title text-center">Today's Top Deals</h3>
                        <div className="text-center" id="hello">
                          <a className="btn btn-primary text-uppercase" href="/v/todays-special-deals/?icid=SN-_-TodaysTopValue">
                            Shop All
                          </a>
                        </div>
                        <div className="boutique-carousel__wrapper" data-boutiqueurl="https://sp1004f730.guided.ss-omtrdc.net/?do=json-boutique&amp;sort=Relevance&amp;count=12&amp;vb=todays-special-deals" data-title="dynamic-carousel">
                          <div className="boutique-carousel__item boutique-carousel__item--skeleton"></div>
                          <div className="boutique-carousel__item boutique-carousel__item--skeleton"></div>
                          <div className="boutique-carousel__item boutique-carousel__item--skeleton"></div>
                          <div className="boutique-carousel__item boutique-carousel__item--skeleton"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="banner-v7hkeulNz8FvXc1HJ1um" className="c-banner__category" style={{ marginBottom: "30px" }}>
                  <div>
                    <h2 className="c-banner__category-list-title">
                      <span>Browse By</span> <strong>Category</strong>
                    </h2>
                  </div>
                  <div data-per-row="7" className="c-banner__category-list no-circles has-border">
                    <div className="c-banner__category-item">
                      <a href="https://www.shophq.com/c/watches/?shopall=1&amp;icid=TB2-_-W-_-ShopAllWatches-_-072122" title="Watches - ">
                        <div className="c-banner__category-custom-image">
                          <div className="banner-overlays">
                            {" "}
                            <img src="https://www.shophq.com/images/store1/Creative2022_J/Com/SDLP_Banners/Storewide/Z2/051822_AsIsSampleSale_Z2/Watchesb.jpg" alt="Watches" className="img-responsive" loading="lazy" role="image" />
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="c-banner__category-item">
                      <a href="https://www.shophq.com/c/kitchen--and--food/?shopall=1&amp;icid=TB2-_-KF-_-ShopAllKitchenFood-_-072122" title="Kitchen &amp; Food - ">
                        <div className="c-banner__category-custom-image">
                          <div className="banner-overlays">
                            {" "}
                            <img src="https://www.shophq.com/images/store1/Creative2022_J/Com/SDLP_Banners/Storewide/Z2/051822_AsIsSampleSale_Z2/KitchenFoodb.jpg" alt="Kitchen &amp; Food" className="img-responsive" loading="lazy" role="image" />
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="c-banner__category-item">
                      <a href="https://www.shophq.com/c/fashion/?shopall=1&amp;icid=TB2-_-F-_-ShopAllFashion-_-072122" title="Fashion - ">
                        <div className="c-banner__category-custom-image">
                          <div className="banner-overlays">
                            {" "}
                            <img src="https://www.shophq.com/images/store1/Creative2022_J/Com/SDLP_Banners/Storewide/Z2/051822_AsIsSampleSale_Z2/Fashionb.jpg" alt="Fashion" className="img-responsive" loading="lazy" role="image" />
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="c-banner__category-item">
                      <a href="https://www.shophq.com/c/home/?shopall=1&amp;icid=TB2-_-H-_-ShopAllHome-_-072122" title="Home - ">
                        <div className="c-banner__category-custom-image">
                          <div className="banner-overlays">
                            {" "}
                            <img src="https://www.shophq.com/images/store1/Creative2022_J/Com/SDLP_Banners/Storewide/Z2/051822_AsIsSampleSale_Z2/Homeb.jpg" alt="Home" className="img-responsive" loading="lazy" role="image" />
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="c-banner__category-item">
                      <a href="https://www.shophq.com/c/jewelry/?shopall=1&amp;icid=TB2-_-J-_-ShopAllJewelry-_-072122" title="Jewelry - ">
                        <div className="c-banner__category-custom-image">
                          <div className="banner-overlays">
                            {" "}
                            <img src="https://www.shophq.com/images/store1/Creative2022_J/Com/SDLP_Banners/Storewide/Z2/051822_AsIsSampleSale_Z2/Jewelryb.jpg" alt="Jewelry" className="img-responsive" loading="lazy" role="image" />
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="c-banner__category-item">
                      <a href="https://www.shophq.com/c/beauty/?shopall=1&amp;icid=TB2-_-B-_-ShopAllBeauty-_-072122" title="Beauty - ">
                        <div className="c-banner__category-custom-image">
                          <div className="banner-overlays">
                            {" "}
                            <img src="https://www.shophq.com/images/store1/Creative2022_J/Com/SDLP_Banners/Storewide/Z2/051822_AsIsSampleSale_Z2/beauty-2.jpg" alt="Beauty" className="img-responsive" loading="lazy" role="image" />
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="c-banner__category-item">
                      <a href="https://www.shophq.com/c/health/?shopall=1&amp;icid=TB2-_-B-_-ShopAllHealth-_-072122" title="Health - ">
                        <div className="c-banner__category-custom-image">
                          <div className="banner-overlays">
                            {" "}
                            <img src="https://www.shophq.com/images/store1/Creative2022_J/Com/SDLP_Banners/Storewide/Z2/051822_AsIsSampleSale_Z2/health-2.jpg" alt="Health" className="img-responsive" loading="lazy" role="image" />
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <span>Sorry, we didn't find any results</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
